/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
//import './menu.scss'
import currentUser from '../../../stores'
import {observer} from 'mobx-react'
import FontIcon from 'react-md/lib/FontIcons/FontIcon'
import List from 'react-md/lib/Lists/List'
import ListItem from 'react-md/lib/Lists/ListItem'
import Subheader from 'react-md/lib/Subheaders/Subheader'
import {withRouter} from 'react-router-dom';
const bg  = 'tealbg'
const Menu= ({location,history}) => {
  
  return (
    <React.Fragment>
      
      <List className={bg}>
          <Subheader primaryText={<h4 className="txtwhite">Menu principal</h4>} />   
          <ListItem 
            className={ location.pathname == '/' ? 'whitebg' : bg}
            style={{marginBottom: '5px'}}
            leftIcon={<FontIcon>home</FontIcon>}
            primaryText={<span  className={location.pathname == '/' ?'menuselect': 'menuside'}>Accueil</span>}
            onClick={() => history.push('/')}
          />
          {currentUser.hasAuth('donateur','creer') ? <ListItem
            style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('donateur') !== -1 ? 'whitebg' : bg}
              leftIcon={<FontIcon>group</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('donateur') !== -1 ? 'menuselect' : 'menuside'} >Annuaire</span>} 
              onClick={() => history.push('/donateur/lister')}
          /> : ''}
        {currentUser.hasAuth('donateur','creer') ? <ListItem
            style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('filtre') !== -1 ? 'whitebg' : bg}
              leftIcon={<FontIcon>sort</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('filtre') !== -1 ? 'menuselect' : 'menuside'} >Filtres annuaire</span>} 
              onClick={() => history.push('/filtre/lister')}
          /> : ''}
          {currentUser.hasAuth('don','creer') ? <ListItem
              style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('don/') !== -1 ? 'whitebg' : bg}
              leftIcon={<FontIcon>star</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('don/') !== -1 ? 'menuselect' : 'menuside'} >Dons</span>} 
              onClick={() => history.push('/don/lister')}
          /> : ''} 
          {currentUser.hasAuth('recufiscal','lister') ? <ListItem
              style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('recufiscal/') !== -1 ? 'whitebg' : bg}
              leftIcon={<FontIcon>recent_actors</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('recufiscal/') !== -1 ? 'menuselect' : 'menuside'} >Reçus fiscaux</span>} 
              onClick={() => history.push('/recufiscal/lister')}
          /> : ''} 

          {currentUser.hasAuth('beneficiaire','creer') ? <ListItem
              style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('beneficiaire') !== -1 ? 'whitebg' : bg}
              leftIcon={<FontIcon>place</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('beneficiaire') !== -1 ? 'menuselect' : 'menuside'}>Bénéficiaires</span>} 
              onClick={() => history.push('/beneficiaire/lister')}
          /> : ''} 


          {currentUser.hasAuth('prieure','creer') ? <ListItem
            style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('prieure') !== -1 ? 'whitebg' :bg}
              leftIcon={<FontIcon>account_balance</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('prieure') !== -1 ? 'menuselect' : 'menuside'} >Prieures</span>} 
              onClick={() => history.push('/prieure/lister')}
          /> : ''}

          {currentUser.hasAuth('user','creer') ? <ListItem
            style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('user') !== -1 ? 'whitebg' : bg}
              leftIcon={<FontIcon>account_circle</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('user') !== -1 ? 'menuselect' : 'menuside'}>Utilisateurs</span>} 
              onClick={() => history.push('/user/lister')}
          /> : ''}
            {currentUser.role.name == 'admin' ? <ListItem
            style={{marginBottom: '5px'}}
              className={location.pathname.indexOf('csv') !== -1 ? 'whitebg' : bg}
              leftIcon={<FontIcon>archive</FontIcon>}
              primaryText= {<span className={location.pathname.indexOf('csv') !== -1 ? 'menuselect' : 'menuside'}>Fichiers csv</span>} 
              onClick={() => history.push('/csv/lister')}
          /> : ''}
      </List>
    </React.Fragment>
  );
}

export default observer(withRouter(Menu));

/*<a className={"menuside"} href="/"><FontIcon>home</FontIcon>Accueil</a>
    
      
{currentUser.hasAuth('prieure','creer') ? <Link className={"menuside"} to="/prieure/lister"><FontIcon>home</FontIcon>Prieures</Link> : ''}

{currentUser.hasAuth('don','creer') ? <Link className={"menuside"} to="/don/lister">Dons</Link> : ''}
{currentUser.hasAuth('beneficiaire','creer') ?  <Link className={"menuside"} to="/beneficiaire/lister">Béneficiaires</Link> : ''}
{currentUser.hasAuth('donateur','creer') ? <Link className={"menuside"} to="/donateur/lister">Donateurs</Link> : ''}

{currentUser.hasAuth('user','lister') ? <Link className={"menuside"} to="/user/lister">Utilisateurs</Link> : ''}
<Subheader primaryText={<h3 style={{color: 'white'}}>Menu</h3>} />
*/
