import React from 'react'
//import {logout} from '../../api/user'
import currentUser from '../../stores'
import {withRouter} from 'react-router-dom'
import AccessibleFakeButton from 'react-md/lib/Helpers/AccessibleFakeButton'
import IconSeparator from 'react-md/lib/Helpers/IconSeparator'
import DropdownMenu from 'react-md/lib/Menus/DropdownMenu'
import FontIcon from 'react-md/lib/FontIcons/FontIcon'

const UserLogout = ({history}) => {

    return (
        <React.Fragment>
            {currentUser.auth ? 
                <DropdownMenu
                    id={`avatar-dropdown-menu`}
                    menuItems={[
                        {
                            id:"account",
                            rightIcon: <FontIcon primary>person</FontIcon>,
                            primaryText: <div id="compte">Mon compte</div>,
                        
                        },
                        {divider: true},
                        {
                            id:"logout",
                            rightIcon: <FontIcon error>cancel</FontIcon>,
                            primaryText: <div id="logout">Se déconnecter</div>,
                        }
                    ]} 
                    anchor={{
                        x: DropdownMenu.HorizontalAnchors.CENTER,
                        y: DropdownMenu.VerticalAnchors.OVERLAP,
                    }}
                    position={DropdownMenu.Positions.BOTTOM_RIGHT}
                    animationPosition="below"
                    
                    simplifiedMenu={true}
                    onClick={(val) => {
                          
                        switch (val.target.id) {
                            case 'compte' :
                                 history.push('/compte')
                            break
                            case 'logout' :
                                location.href = '/logout'
                            break
                        }
                    }}
                >
                    <AccessibleFakeButton
                        component={IconSeparator}
                        iconBefore
                        label={
                            <IconSeparator label={`${currentUser.username}(${currentUser.role.name})`}>
                            <FontIcon style={{color: 'white'}}>arrow_drop_down</FontIcon>
                            </IconSeparator>
                        }
                    >
                        <FontIcon style={{color: 'white'}}>account_circle</FontIcon>
                    </AccessibleFakeButton>
                </DropdownMenu>
  
                : ''
            }

        </React.Fragment>
    )
}

export default withRouter(UserLogout)

