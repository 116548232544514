import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import currentUser from '../../stores'

const PrivateRoute = (props)  => {
        const  { component: Component, ...rest } = props
    
        return (
            <Route {...rest} render={(props) => (
              currentUser.auth  ? <Component {...props} /> : 
                                  <Redirect to={{pathname: '/login'}} />
            )} />
        )
    
}

export default PrivateRoute 
