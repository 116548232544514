import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Error404 extends Component {
  componentWillMount() {
    const { staticContext } = this.props;
    if (staticContext) {
      staticContext.missed = true;
    }
  }

  render() {
    return <h3>Désolé ! Cette page n'existe pas. </h3>;
  }
}

Error404.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  staticContext: PropTypes.object,
};

Error404.defaultProps = {
  staticContext: {},
};

export default Error404;
