import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Error403 extends Component {
  componentWillMount() {
    const { staticContext } = this.props;
    if (staticContext) {
      staticContext.missed = true;
    }
  }

  render() {
    return <div className="main">
        <h3>Vous n'avez les permissions pour effectuer cette tâche.</h3>
    </div>;
  }
}

Error403.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  staticContext: PropTypes.object,
};

Error403.defaultProps = {
  staticContext: {},
};

export default Error403;
