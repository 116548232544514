import React from 'react';
import logo from './80_croix.png';


function Logo() {
  return (
    <img src={logo} alt="Logo" style={{ width: '70px' }} />
  )
}

export default Logo;

/***
 *
 */