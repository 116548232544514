import {types, onPatch} from "mobx-state-tree"

// declaring the shape of a node with the type `Todo`
const auth_target = types.model({
    label: ''
})

const has_auth = types.model({
    name: '',
    auth_target: types.array(auth_target)
})

const role = types.model({
    name: '',
    has_auth: types.array(has_auth)
})

const User = types.model("currentUser",{
    uiduser: '',
    username: '',
    userslug: '',
    prieure: '',
    prieureslug: '',
    uidprieure: '',
    role: types.maybe(role),
    auth: false,
    actif: true
}).actions(self => ({
    setUser (user)  {
      
        if(user !== undefined) {
            self.username = user.username
            self.userslug = user.slug
            self.prieure = user.prieure
            self.prieureslug = user.prieureslug
            self.uidprieure = user.uidprieure
            self.role.name = user.role.name
            self.role.has_auth = user.role.has_auth
            self.auth = user.auth
            }else{
                const  defaultUser = {
                    uiduser: '',
                    username: '',
                    userslug: '',
                    prieureslug: '',
                    prieure: '',
                    uidprieure: '',
                    role: {name: '', has_auth: []},
                    auth: false
                  }
                  self.user = defaultUser
            }
    },
    unsetUser(){
        self.username = ''
        self.uiduser = ''
        self.userslug = ''
        self.prieure = ''
        self.uidprieure = ''
        self.role = {name: '',has_auth:[]}
        self.auth = false
        
    }
})).views(self =>({
    hasAuth(target, action){
      let auth = self.role.has_auth.find(a => a.name === action)
        if(auth) {
            //console.log(auth)
            let auth_target = auth.auth_target.find(at => at.label === target)
            if(auth_target){
                //console.log(auth_target)
                return true
            }
        }
        return false
       
    },
    getUser() {
        return {username: self.username,role: self.role, auth: self.auth}
    }
}))

// creating a tree based on the "Todo" type, with initial data:
const currentUser = User.create({
    role: {name: '',has_auth:[]}
})
/*onPatch(currentUser, patch => {
    console.log(patch)
})*/
export default currentUser