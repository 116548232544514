import React from 'react'
import Snackbar from 'react-md/lib/Snackbars/SnackbarContainer'
import snackStatus from '../../stores/snack'
import {observer} from 'mobx-react'

const Snack = () => {
   
          return (
                  <Snackbar
                    id="interactive-snackbar"
                    toasts={ snackStatus.toasts.map(t => t)}
                    autohide={snackStatus.autohide.length ? snackStatus.autohide[0] : true}
                    autohideTimeout={snackStatus.autohideTimeout.length ? snackStatus.autohideTimeout[0] : 2000}
                    onDismiss={snackStatus.clearStatus}
                    className={snackStatus.className.length ? snackStatus.className[0]: ''}
                 
                    /> 
          )
      
}

export default observer(Snack)