import {types, applySnapshot,detach} from 'mobx-state-tree'

const toasts = types.model({
    text: '',
    action: 'Fermer'
})
const snackmodel = types.model({
    toasts: types.maybe(types.array(toasts)),
    autohide: types.array(types.boolean),
    autohideTimeout: types.array(types.number),
    className: types.array(types.string)
}).actions(self => ({
    setStatus(data) {
        detach(self.toasts)
        const clear = {
            className: [],
            autohide: [],
            autohideTimeout: []

        }
        applySnapshot(self,clear)
        applySnapshot(self,data)
    },
    setDataFromRequest(data) {
        detach(self.toasts)
        const clear = {
            className: [],
            autohide: [],
            autohideTimeout: []
        }
        applySnapshot(self,clear)
        let snackdata = {
            toasts: [],
            className: [],
            autohide: [],
            autohideTimeout: []
        }
        
        if (! data.error) {
            if(data.msg !== ''){
                snackdata.toasts.push({text: data.msg, action: ''})
                snackdata.className.push('snacksuccess')
                snackdata.autohide.push(true)
                snackdata.autohideTimeout.push(2000)
            }
            
            if (data.info !== undefined && data.info !== '') {
                snackdata.toasts.push({text: data.info, action: 'Fermer'})
                snackdata.className.push('snackinfo')
                snackdata.autohide.push(false)
                snackdata.autohideTimeout.push(6000)
            }
        }else{
            snackdata.toasts.push({text: data.alert, action: 'Fermer'})
            snackdata.className.push('snackalert')
            snackdata.autohide.push(true)
            snackdata.autohideTimeout.push(3000)
        }
       
        
        applySnapshot(self,snackdata)
       
    },
    clearStatus() {
        detach(self.toasts[0])
        if(self.className.length > 0) {
             self.className.shift()
             self.autohide.shift()
             self.autohideTimeout.shift()
        }
    }
}))

const snackStatus = snackmodel.create({
    toasts: [],
    autohideTimeout: [],
    autohide: [], 
    className: []
})

export default snackStatus