import React from 'react';

import Menu from './Menu';

const  Header = () => {
  return (
    <div>
     
      <Menu />
    </div>
  );
}

export default Header;
