import 'normalize.css/normalize.css';

import React from 'react';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect'
//import DevTools from 'mobx-react-devtools'
import Helmet from 'react-helmet';
//import {withJob} from 'react-jobs'
import config from '../../config';

//import {isAuth} from '../api/user'

import './globals.css';
import './global.scss';
import Error404 from './Error404';
import Error403 from './Error403';
import Header from './Header';

import PrivateRoute from './PrivateRoute/PrivateRoute'

import Home from '../components/Home'
//import TestRoute from './Test/routes'
import Pdf from './Pdf'
import {AsyncPrieureRoute} from './Prieure/routes'
import {AsyncUserRoute} from './User/routes'
import {AsyncBeneficiaireRoute} from './Beneficiaire/routes'
import {AsyncFiltreRoute} from './Filtre/routes'
import {AsyncDonateurRoute} from './Donateur/routes'
import {AsyncDonRoute} from './Don/routes'
import {AsyncRecufiscalRoute} from './Recufiscal/routes'
import {AsyncCsvRoute} from './Csv/routes'
import CompteRoute from './Compte/routes'
import LoginRoute from './Login/routes'
import Logout from './Login/Logout'
import currentUser from '../stores'
import Logo from './Header/Logo'
import MySnack from './Common/MySnack'
const  App = () => {

  return (
    <div >
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta
          name="application-name"
          content={config('htmlPage.defaultTitle')}
        />
        <meta name="description" content={config('htmlPage.description')} />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#2b2b2b" />
        <meta
          name="msapplication-TileImage"
          content="/favicons/mstile-144x144.png"
        />
       
        <title>{config('htmlPage.defaultTitle')}</title>
     
        <link rel="manifest" href="/manifest.json" />

        {/*
          NOTE: This is simply for quick and easy styling on the demo. Remove
          this and the related items from the Content Security Policy in the
          global config if you have no intention of using milligram.
        */}
        <link
          rel="stylesheet"
          href="//fonts.googleapis.com/css?family=Roboto:300,300italic,700,700italic"
        />
        
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"/>

      </Helmet> 
      <div className="container-header tealbg">
              <div className='titre '>
                  <Logo/>
                 
              </div>

              <div className='info '>
                  {currentUser.auth === true ?  <h3 style={{marginBottom: '0'}} className="txtwhite">{'Gestion dons & reçus fiscaux'}</h3> : ''}
                  {currentUser.prieure !== '' ? <h4 style={{textAlign: 'center'}} className="txtwhite">Prieuré de {currentUser.prieure}</h4> : 
                      currentUser.auth ? <h4 className="txtwhite">Administrateur</h4>: ''}
              </div>

              <div className='compte '><Logout/></div>
      </div>
      <div className={currentUser.auth === true ? 'containerLogged' : 'container'}>
        
          <div className={currentUser.auth ? "sidebar tealbg": "sidebar"}>{currentUser.auth ? <Header/> : <div></div>}</div>
          <div className={currentUser.auth ? "firstmain lightgreybg" : "firstmain"}>
            <Switch>
              
                <Route exact path="/" component={Home} />
                <Route exact path="/pdf/lister" component={Pdf}/>
                <Route  path="/login" render={(router) => <LoginRoute router={router} />}/>
                <Route  path="/logout" render={() => <Redirect to="/login" />}/>
               
                <PrivateRoute  path="/prieure"  component={AsyncPrieureRoute}  /> 
                <PrivateRoute  path="/recufiscal"  component={AsyncRecufiscalRoute}  /> 
                <PrivateRoute  path="/user" component={AsyncUserRoute}  />
                <PrivateRoute  path="/beneficiaire" component={AsyncBeneficiaireRoute}  />
                <PrivateRoute  path="/compte" component={CompteRoute}  /> 
                <PrivateRoute  path="/donateur" component={AsyncDonateurRoute}  /> 
                <Route  path="/filtre" component={AsyncFiltreRoute}  />
                <PrivateRoute  path="/don" component={AsyncDonRoute}  /> 
                <PrivateRoute  path="/csv" component={AsyncCsvRoute}  /> 
                <Route  path="/403" component={Error403}  /> 
                <Route component={Error404} />
              
            </Switch>
         </div>
         {currentUser.auth && <div className="footer tealbg"></div>}
      </div>
      <MySnack/>
    </div>
  );
  
}

export default App;

/**
 *  <Route  path="/403" component={Error403}  /> 
 *           {currentUser.auth == true ? 
              <div className="footer middlegreybg">copyright @prieuré de Mulhouse</div>
             : ''}
 */

 /** *
  * <PrivateRoute  path="/prieure"  component={AsyncPrieureRoute}  /> 
                <PrivateRoute  path="/user" component={AsyncUserRoute}  />
                <PrivateRoute  path="/beneficiaire" component={AsyncBeneficiaireRoute}  />
                <PrivateRoute  path="/compte" component={CompteRoute}  /> 
                <PrivateRoute  path="/donateur" component={AsyncDonateurRoute}  /> 
                <PrivateRoute  path="/don" component={AsyncDonRoute}  /> 
 */
