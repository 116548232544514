import React from 'react';


export default class Pdf extends React.Component {
   
    constructor(props) {
        super(props);
        this.state =  { numPages: null, pageNumber: 1 }
    }

     

    /*onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    };*/
  
    goToPrevPage () {
        this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    }
     

    goToNextPage () {
        this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
    }
   
  
    render() {
      const { pageNumber, numPages } = this.state;
  
      return (
        <div>

        </div>
      );
    }
  }

